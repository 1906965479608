import { useRouter } from 'next/router';
import type { SyntheticEvent } from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { useCardClick } from '@src/components/ProductList/components/Card/useCardClick';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { MONETIZATION_INTERNAL } from '@src/helpers/advertising';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';

import type { LightweightCardOnClickArgs } from '../types';

export const useCardAnalytics = (
  offer: IClientMicrocreditListItem,
  position: number,
  onClick?: (e: SyntheticEvent, args: LightweightCardOnClickArgs) => void,
) => {
  const router = useRouter();

  const { organization, advertising, name, _id } = offer;

  const { sourceLink, pixelDisplayLink } = useCardClick({ offer, position });

  const source = `${sourceLink}|position_${position}`;
  const affSub4: AffSub4 = {
    organizationName: organization.name,
    productName: name,
    organizationId: organization._id,
    productId: _id,
  };

  const mfoSiteLink = usePixel(advertising?.monetization?.pixels?.click, {
    sub: advertising.advSub,
    affSub4,
    type: advertising?.productType,
    source,
    pixelType: PIXELS_TYPES.CLICK,
  });

  const openMfoSite = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (onClick) {
      onClick(e, {
        mfoUrl: mfoSiteLink,
      });

      return;
    }

    sendAdvertisingButtonClickEvent(offer);

    if (!mfoSiteLink) {
      return;
    }

    if (advertising?.monetization?.kind === MONETIZATION_INTERNAL) {
      router.push(mfoSiteLink);
      return;
    }

    open({ href: mfoSiteLink, target: '_blank', features: 'noopener' });
  };

  return { mfoSiteLink, source, affSub4, pixelDisplayLink, openMfoSite };
};
