import cn from 'classnames';
import type { SyntheticEvent } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import { useMediaQuery } from '@src/components/item/hooks';
import { Pixel } from '@src/components/Pixel';
import { LightweightCardView } from 'entities/Microcredits/ui';
import { getCardBadge } from 'entities/Microcredits/ui/LightweightCard';
import { IS_PROMO_FEATURE_ENABLED } from 'entities/Promo/lib/getPromoVariant';
import { isFullDealOrganization } from 'shared/config/fullDealOrganizationIds';
import { AdaptiveText } from 'shared/ui';

import { useCardAnalytics } from '../lib';
import type { LightweightCardActionsArgs, LightweightCardContentSettings, LightweightCardOnClickArgs } from '../types';

import { DEFAULT_CONTENT, getCardContent, PIXEL_DISPLAY_WRAPPER_STYLES } from './LightweightCard.config';
import styles from './LightweightCard.module.scss';

interface Props {
  offer: IClientMicrocreditListItem;
  position: number;

  hasUtmLabel: boolean;

  contentSettings?: LightweightCardContentSettings;
  currency?: Currency;
  showPromo?: boolean;

  renderActions?: (args: LightweightCardActionsArgs) => React.ReactNode;
  onClick?: (e: SyntheticEvent, args: LightweightCardOnClickArgs) => void;
}

export const LightweightCard = ({
  offer,
  currency = Currency.RUB,
  position,
  hasUtmLabel,
  contentSettings = DEFAULT_CONTENT,
  showPromo = true,
  onClick,
  renderActions,
}: Props) => {
  const isPhone = useMediaQuery(0, 450);
  const isMobile = useIsMobile();

  const { mfoSiteLink, source, affSub4, pixelDisplayLink, openMfoSite } = useCardAnalytics(offer, position, onClick);

  // @todo: Добавить эту штуку если данная карточка добавится на страницу с listKey === ListKeys.WL_PAGE
  // а лучше передать это через пропс
  //   const isTemporaryTerm = isReviewer && listKey === ListKeys.WL_PAGE;

  // @todo: Добавить эту штуку если данная карточка добавится на страницу 2GIS
  // const sourceAffI = isPartner2Gis ? PARTNER_2GIS_SOURCE_HO_PARAM : `${sourceLink}|position_${position}`;

  const content = getCardContent(offer, currency, isPhone, contentSettings);
  const badge = contentSettings.badge.visible ? getCardBadge(offer, hasUtmLabel, styles) : [];

  const isShowPromo =
    showPromo && isFullDealOrganization(offer.organization._id) && IS_PROMO_FEATURE_ENABLED && isMobile;

  return (
    <LightweightCardView
      offer={offer}
      content={content}
      badge={badge}
      onCardClick={openMfoSite}
      actions={
        renderActions ? (
          renderActions({ onClick: openMfoSite })
        ) : (
          <Typography.Link className={styles.button} href={mfoSiteLink} onClick={openMfoSite} rel="nofollow">
            Получить деньги
          </Typography.Link>
        )
      }
      pixelDisplay={
        <Pixel wrapperStyles={PIXEL_DISPLAY_WRAPPER_STYLES} source={source} link={pixelDisplayLink} affSub4={affSub4} />
      }
      promo={
        isShowPromo && (
          <AdaptiveText
            longText="Дарим 500₽ за займ"
            shortText="Дарим 500₽ за займ"
            renderText={(props) => (
              <Typography.Text size={12} {...props} className={cn(styles.fullDealLabel, props.className)} />
            )}
          />
        )
      }
    />
  );
};
